$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.root {
  @include media-breakpoint-up(md) {
    text-align: right;
  }

  a {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }

  img {
    max-height: 40px !important;
    width: auto;
  }
}
